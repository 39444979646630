import React, { useRef, useState, useEffect } from "react";
import * as moment from "moment";
import PictureInPictureAltIcon from "@material-ui/icons/PictureInPictureAlt";

export const PictureInPictureVideo = ({ videoPath, videoCreated }) => {
  const [isPipActive, setIsPipActive] = useState(false);
  const vidRef = useRef();

  const togglePictureInPictureMode = async () => {
    if (!vidRef.current) return;
    try {
      if (!document.pictureInPictureElement) {
        await vidRef.current.play();
        await vidRef.current.requestPictureInPicture();
        setIsPipActive(true);
      }
    } catch (error) {
      console.error("Error with Picture-in-Picture:", error);
    }
  };

  useEffect(() => {
    const video = vidRef.current;

    const handleEnterPip = () => setIsPipActive(true);
    const handleExitPip = () => setIsPipActive(false);

    if (video) {
      video.addEventListener("enterpictureinpicture", handleEnterPip);
      video.addEventListener("leavepictureinpicture", handleExitPip);
    }

    return () => {
      if (video) {
        video.removeEventListener("enterpictureinpicture", handleEnterPip);
        video.removeEventListener("leavepictureinpicture", handleExitPip);
      }
    };
  }, []);

  return (
    <div
      onClick={togglePictureInPictureMode}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      {isPipActive ? (
        <div
          style={{
            height: 50,
            width: 50,
            marginRight: 8,
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PictureInPictureAltIcon
            fontSize="medium"
            style={{ color: "white" }}
          />
        </div>
      ) : (
        <video
          ref={vidRef}
          style={{
            height: 50,
            width: 50,
            marginRight: 8,
          }}
          controls={false}
          muted={true}
        >
          <source src={videoPath} type="video/mp4" />
        </video>
      )}
      <span>{moment(parseInt(videoCreated)).format("MMM D, YYYY")}</span>
    </div>
  );
};
