import React from 'react';
import { Typography, Button } from '@material-ui/core';
import KeyValueList from './key_value';
import { ParameterSlider } from './model_params';

function SwingDetails({ 
  jsonData, setJsonData, 
  fileIdx, defaultParameters, 
  setDefaultParameters, handleToggleDetails,
  goldsetDescription
}) {

  const handleNotesChange = (notes) => {
    const newJsonData = jsonData
    jsonData[fileIdx].notes = notes
  
    setJsonData(newJsonData);
  };

  const handleTempChange = (event, val) => {
    const id = event.target.id;
    setDefaultParameters((prev) => ({
      ...prev,
      [id]: val,
    }));
  };

  return (
    <div style={{
        height: '80vh', // Full viewport height to center vertically                  
        overflow: 'auto', // Enable scrolling when content overflows
        textAlign: 'center', // Center text inside the div (optional)
      }}>
        <Button variant='contained' color='primary' onClick={handleToggleDetails}>Show swing</Button>
        <div>
          <br/>
            {goldsetDescription}
          <br/>
          <br/>
            {/* { (
              <div>
                <Typography variant='h2'>Notes</Typography>
                <TextField variant="standard" value={jsonData[fileIdx].notes}/>
                <div
                  contentEditable='true'
                  onInput={e => handleNotesChange(e.currentTarget.textContent)}
                >
                  {jsonData[fileIdx].notes}
                </div>
                <br/>
              </div>
            )} */}
            {/* <Typography variant='h2'>Watchlist</Typography>
            <List sx={{ listStyleType: 'circle', pl: 2 }}>
              {
                jsonData[fileIdx].watchlist?.map((value, index) => (
                  <ListItem key={index} sx={{ display: 'list-item' }}>
                    <ListItemText primary={value}/>
                  </ListItem>
                ))
              }
            </List> */}
            <ParameterSlider defaultParameters={defaultParameters} handleTempChange={handleTempChange}/>            
            <KeyValueList data={jsonData[fileIdx].indicators}/>          
        </div>
      </div>
  );
}

export default SwingDetails;
