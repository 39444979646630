import React, { useState } from 'react';
import { Tab, Tabs, Select, MenuItem, Button, TextField, Box, FormControl, InputLabel, Typography, Input } from '@material-ui/core';
import { axiosWithToken, functionBaseUrl } from '../../../common/firebase';
import Swal from 'sweetalert2';

const golfClubTypes = [
  'D',
  '3i',
  '4i',
  '5i',
  '6i',
  '7i',
  '8i',
  '9i',
  '2H',
  '3H',
  '4H',
  '5H',
  '6H',
  '3W'
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function JsonLoader({ 
  goldsets, 
  goldset, 
  handleGoldsetChange, 
  setJsonData, 
  setJsonModel, 
  fileIdx, 
  setJsonParameters,
  setGoldsetDescription
}) {  

  const [userId, setUserId] = useState('');
  const [clubType, setClubType] = useState('');
  const [numSwings, setNumSwings] = useState(1);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    getSwingData(userId, clubType, numSwings)
  };

  async function getSwingData(user_id, club_type, num_swings) {
    return axiosWithToken(functionBaseUrl+'/api/llm/get-swing-data', {    
      method: 'post',
      data: {
        user_id: user_id,
        club_type: club_type,
        num_swings: num_swings
      }
    }).then(response => {
      let data = response.data
      if (data.length <= 0) {
        Swal.fire({
          title: '<p style="font-size:70%;">No user data found</p>',
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
        });
      }
      setJsonData(data)
      setGoldsetDescription(`${user_id}_${num_swings}_${club_type}`)  
      return data
    })
    .catch(err => {
      console.log(err.response?.data)
      return 0
    });   
  }

  function handleFileUpload(event) {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const str = e.target.result;
        try {
          const data = JSON.parse(str);
          if (verifyJsonData(data)) {
            setJsonData(data);            
            setJsonModel(data[fileIdx].model);
            setJsonParameters(data[fileIdx].model_parameters)  
          } else {
            console.error('Invalid JSON structure.');
          }
        } catch (error) {
          console.error('Failed to parse JSON:', error);
          Swal.fire({
            title: '<p style="font-size:70%;">Invalid json</p>',
            icon: 'error',
            confirmButtonText: 'Ok',
            allowOutsideClick: true,
          });
        }
      }
      reader.readAsText(file);
    }
  }

  const verifyJsonData = (data) => {    
    if (data.length <= 0 || !data[0].video_url || !data[0].system_message) {
      Swal.fire({
        title: '<p style="font-size:70%;">Incorrect json format</p>',
        icon: 'error',
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
      });
      return false
    }
    return true
  }

  const fetchJson = () => {
    return axiosWithToken(functionBaseUrl+'/api/llm/get-goldset', {
      method: 'post',
      data: {
        filePath: goldset
      }
    }).then(response => {
      let data = response.data
      if (verifyJsonData(data)) {
        setJsonData(data);            
        setGoldsetDescription(`${goldset}`)  
        setJsonModel(data[fileIdx].model);
        setJsonParameters(data[fileIdx].model_parameters)  
      } else {
        console.error('Invalid JSON structure.');
      }
      // return data
    })
    .catch(err => {
      console.log(err.response?.data)
      // return {}
    }); 
  }

  const [viewA, setViewA] = useState(0);  

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={viewA} onChange={(e, v) => { setViewA(v) }} aria-label="basic tabs example">
        <Tab label="Search" />
        <Tab label="Preloaded Data" />
        <Tab label="Upload JSON" />
      </Tabs>
    </Box>
    <CustomTabPanel value={viewA} index={0}>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 300 }}>
        <TextField
          label="User ID"
          variant="outlined"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          required
        />
        <br/>
        <FormControl variant="outlined" required>
          <InputLabel>Golf Club Type</InputLabel>
          <Select
            value={clubType}
            onChange={(e) => setClubType(e.target.value)}
            label="Golf Club Type"
          >
            {golfClubTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br/>
        <TextField
          label="Number of Swings"
          type="number"
          variant="outlined"
          value={numSwings}
          onChange={(e) => setNumSwings(parseInt(e.target.value, 10))}
          required
          InputProps={{ inputProps: { min: 1 } }}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>          
      </Box>
    </CustomTabPanel>
    <CustomTabPanel value={viewA} index={1}>
      <Box sx={{ mt: 4 }}>
        <Select displayEmpty value={goldset} onChange={handleGoldsetChange}>
          <MenuItem value="">Select a json</MenuItem>
          {goldsets?.map((goldset, index) => (
            <MenuItem key={goldset} value={goldset}>{goldset}</MenuItem>
          ))}
        </Select>
        <Button onClick={fetchJson} color="primary" variant="contained">Load JSON</Button>        
      </Box>
    </CustomTabPanel>
    <CustomTabPanel value={viewA} index={2}>
      <Box>
        <Input type="file" accept="application/json" name="file1" onChange={handleFileUpload} />
      </Box>
    </CustomTabPanel>      
      {/* <Box sx={{ textAlign: 'left', mt: 4 }}>
      <Button variant="contained" onClick={toggleView}>
        Toggle View
      </Button>

      {viewA ? (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 300 }}>
          <Typography variant="h1">Search</Typography>
            
      ) : (
        
      )}
    </Box>           */}
    </div>
  );
}

export default JsonLoader;


        // user_id: "emVxZwcLZ8hoqD0a3MNJslrmOkx2",
        // club_type: "D",
        // num_swings: 5