import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import firebase, {
  functionBaseUrl,
  axiosWithToken,
} from "../../common/firebase";
import { Swings } from "./Swings";
import Swal from "sweetalert2";
import JSZip, { filter } from "jszip";
import axios from "axios";
import { SelectV3 } from "../../components/redesign-components/SelectV3/SelectV3";
import { SelectV3WithDatePicker } from "../../components/redesign-components/SelectV3/SelectV3WithDatePicker";
import * as moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { IndicatorSelectList } from "./components/IndicatorSelectList/IndicatorSelectList";
import { HorizontalScrollWrapper } from "./components/HorizontalScrollWrapper/HorizontalScrollWrapper";
import { PictureInPictureVideo } from "./components/PictureInPictureVideo/PictureInPictureVideo";
import { SelectV3WithCategories } from "../../components/redesign-components/SelectV3/SelectV3WithCategories";
import { SelectV3WithServerSearch } from "../../components/redesign-components/SelectV3/SelectV3WithServerSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: "fit-content",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //flexWrap: 'wrap',
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  text: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  vertDivider: {
    margin: theme.spacing(2, 0),
    //padding: theme.spacing(0, 2),
    //marginLeft: 30,
    //marginRight: 30,
  },
  heading: {
    fontFamily: "Manrope",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: 15,
  },
  card: {
    width: "100%",
    //minWidth: '30%',
    //maxWidth: '300px',
    height: "100%",
    borderRadius: "12px",
    border: "1px solid #EAECF0",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  cardText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    //marginBottom: 10,
    //color: 'white',
  },
  displaySm: {
    fontFamily: "Manrope",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "38px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  displayMd: {
    fontFamily: "Manrope",
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    marginRight: 10,
  },
  smallCard: {
    width: "200px",
    //maxWidth: '200px',
    height: "120px",
    borderRadius: "12px",
    border: "1px solid #EAECF0",
    padding: theme.spacing(2.5),
    marginRight: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "48%",
      marginRight: "2%",
      marginBottom: "2%",
    },
  },
  button: {
    width: "fit-content",
    height: "100%",
    padding: "10px 16px",
    borderRadius: "64px",
    //border: '1px solid #E9DDFD',
    gap: "8px",
    background: "#F2F4F7",
    textTransform: "none",
    marginTop: "2%",
    marginRight: "1%",
  },
  topButton: {
    width: "fit-content",
    height: "100%",
    padding: "10px 16px",
    borderRadius: "64px",
    //border: '1px solid #E9DDFD',
    gap: "8px",
    background: "#F2F4F7",
    textTransform: "none",
    //marginTop: '2%',
    //marginRight: '1%',
  },
  addButton: {
    width: "fit-content",
    height: "100%",
    padding: "8px 14px",
    borderRadius: "64px",
    gap: "8px",
    background: "#F9F5FF",
    textTransform: "none",
    marginRight: "1%",
  },
  removeButton: {
    width: "fit-content",
    height: "36px",
    padding: "8px 14px",
    borderRadius: "64px",
    gap: "8px",
    //background: '#B42318',
    textTransform: "none",
    marginRight: "1%",
  },
}));

const fixedColumns = [
  {
    field: "videoPath",
    title: "Date",
    sorting: true,
    //defaultSort: "desc",
    render: (rowData) => {
      return rowData?.videoPath?.includes(".com") ? (
        // <div style={{ display: "flex", alignItems: "center" }}>
        //   <video
        //     style={{
        //       height: 50,
        //       width: 50,
        //       cursor: "pointer",
        //       marginRight: 8,
        //     }}
        //     allowFullScreen
        //     controls
        //     muted={true}
        //   >
        //     <source src={rowData.videoPath} type="video/mp4" />
        //   </video>
        //   <span>
        //     {moment(parseInt(rowData.videoCreated)).format("MMM D, YYYY")}
        //   </span>
        // </div>
        <PictureInPictureVideo
          videoPath={rowData.videoPath}
          videoCreated={rowData.videoCreated}
        />
      ) : (
        <b>{rowData?.videoPath}</b>
      );
    },
  },
  { field: "userData.golfClub", title: "Club", sorting: true },
  { field: "userData.fullName", title: "Golfer", sorting: true },
];

const FilterButton = ({ text, onClose }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "4px 10px",
        backgroundColor: "#F2F4F7",
        color: "#344054",
        borderRadius: "16px",
        gap: "4px",
        fontSize: "14px",
        fontWeight: 600,
        alignItems: "center",
        whiteSpace: "nowrap",
      }}
    >
      <div>{text.charAt(0).toUpperCase() + text.slice(1)}</div>
      <CloseIcon
        style={{ cursor: "pointer" }}
        fontSize="small"
        onClick={onClose}
      />
    </div>
  );
};

function SwingLibraryComponent() {
  const [isLoading, setIsLoading] = useState(true);

  const [analysisAggregates, setAnalysisAggregates] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalSwings, setTotalSwings] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [videos, setVideos] = useState([]);

  const [filterOptionsUsers, setFilterOptionsUsers] = useState([]);
  const [filterValueUsers, setFilterValueUsers] = useState([]);

  const [filterValueDate, setFilterValueDate] = useState(null);

  const [filterValueClub, setFilterValueClub] = useState([]);

  const [filterValueCameraAngle, setFilterValueCameraAngle] = useState([]);

  const [filterValueFilters, setFilterValueFilters] = useState([]);

  const [filterValueIndicators, setFilterValueIndicators] = useState([]);

  const [isIndicatorFilterOpen, setIsIndicatorFilterOpen] = useState(false);

  const [filterValueAnalyzed, setFilterValueAnalyzed] = useState([]);
  const [filterValueDominantHand, setFilterValueDominantHand] = useState([]);

  const [sorting, setSorting] = useState({
    sort: "videoCreated",
    order: "desc",
  });

  const [tableColumns, setTableColumns] = useState(fixedColumns);

  const loadStudents = async () => {
    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;
    const response = await axiosWithToken(
      `${functionBaseUrl}/api/v3/loadStudentsForCoach`,
      {
        method: "POST",
        data: {
          limit: 20,
          offset: 0,
          sort: "userCreated",
          order: "desc",
          filter: { firstNameOrLastNameOrEmail: "" },
          coachId: uid,
          isPendingInviteNeeded: false,
          subscriptionLimit: 20,
        },
      }
    );

    const result = response.data.result.map((el) => {
      return { text: el.user.userName, value: el.user.id };
    });
    setFilterOptionsUsers(result);
  };

  const loadData = async (
    page,
    size,
    sorting,
    filterValueUsers,
    filterValueDate,
    filterValueClub,
    filterValueCameraAngle,
    filterValueAnalyzed
  ) => {
    setIsLoading(true);

    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;

    const requestBody = {
      limit: size,
      offset: page * size,
      sort: sorting.sort,
      order: sorting.order,
      userId: uid,
    };

    if (filterValueUsers.length) {
      requestBody.userId = filterValueUsers[0];
    }

    if (
      filterValueDate &&
      new Date(filterValueDate.startDate).valueOf() !==
        new Date(filterValueDate.endDate).valueOf()
    ) {
      requestBody.dateRangeStart = new Date(
        filterValueDate.startDate
      ).valueOf();
      requestBody.dateRangeEnd = new Date(filterValueDate.endDate).valueOf();
    }

    if (filterValueClub.length) {
      requestBody.club = filterValueClub[0];
    }

    if (filterValueCameraAngle.length) {
      requestBody.cameraAngle = filterValueCameraAngle[0];
    }

    if (filterValueAnalyzed.length) {
      requestBody.analyzed = filterValueAnalyzed[0];
    }

    if (filterValueDominantHand.length) {
      requestBody.domHand = filterValueDominantHand[0];
    }

    //const response = await axiosWithToken('http://localhost:5000/sportsbox-development/us-central1/api/v1/swingList',
    const response = await axiosWithToken(
      `${functionBaseUrl}/api/v1/swingList`,
      {
        method: "POST",
        data: requestBody,
      }
    );
    setVideos(response?.data?.videos);
    setAnalysisAggregates(response?.data?.analysisAggregates);
    setTotalSwings(response?.data?.totalSwings);
    setIsLoading(false);
  };

  useEffect(() => {
    loadStudents();
  }, []);

  useEffect(() => {
    loadData(
      currentPage,
      pageSize,
      sorting,
      filterValueUsers,
      filterValueDate,
      filterValueClub,
      filterValueCameraAngle,
      filterValueAnalyzed,
      filterValueDominantHand
    );
  }, [
    currentPage,
    pageSize,
    filterValueCameraAngle,
    filterValueClub,
    filterValueDate,
    filterValueUsers,
    filterValueAnalyzed,
    filterValueDominantHand,
    sorting,
  ]);

  useEffect(() => {
    if (filterValueFilters.length) {
      if (
        !filterValueFilters.includes("analyzed") &&
        filterValueAnalyzed.length
      )
        setFilterValueAnalyzed([]);

      if (
        !filterValueFilters.includes("dominantHand") &&
        filterValueDominantHand.length
      )
        setFilterValueDominantHand([]);
    }

    if (currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [filterValueFilters]);

  useEffect(() => {
    if (filterValueIndicators.length) {
      setTableColumns([
        ...fixedColumns,
        ...filterValueIndicators.map((el) => ({
          field: "analysis.indicators." + el.value,
          title: el.text,
          sorting: false,
        })),
      ]);
    }
  }, [filterValueIndicators]);

  const downloadVideos = (videos) => {
    Swal.fire({
      title:
        '<p style="font-size:70%;">Downloading videos...</p><br /><img width="10%" src="images/loading.gif" alt="Loading" />',
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      customClass: {
        container: "my-swal",
      },
    });

    const names = Object.entries(videos).map(([id, v]) => {
      return (
        v?.videoOrigName +
        (v?.videoType !== "video/*" ? "." + v?.videoType : "")
      );
    });
    const promises = Object.entries(videos).map(([id, obj]) => {
      //Get video from url and return as a blob
      return axios({
        url: obj?.videoPath,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log("Failed to download " + obj?.videoOrigName);
          return null;
        });
    });
    //If only one item is selected, do not zip the file and just download it
    if (promises.length === 1) {
      promises[0].then((response) => {
        if (response) {
          downloadFile(names[0], response.data);
          Swal.close();
        }
      });
    } else {
      //Zip all video files and download them
      Promise.all(promises).then((responses) => {
        const zip = new JSZip();
        responses.forEach((res, index) => {
          if (res) {
            zip.file(names[index], res.data);
          }
        });
        zip.generateAsync({ type: "blob" }).then((blob) => {
          downloadFile("videos.zip", blob);
          Swal.close();
        });
      });
    }
  };

  //Downloads a blob with filename name
  const downloadFile = (name, blob) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  function downloadJSONAsCSV(jsonData, filename) {
    try {
      // Flatten data and get unique columns
      const { csvData, columns } = flattenData(jsonData);

      // Create a CSV file and allow the user to download it
      let blob = new Blob([csvData], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);

      // Create a link element to simulate a click and trigger the download
      let a = document.createElement("a");
      a.href = url;
      a.download = filename + ".csv";
      document.body.appendChild(a);
      a.click();

      // Clean up: remove the link element and revoke the URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during CSV download:", error);
    }
  }

  function flattenData(jsonData) {
    // Get unique column names, including cleaned up indicator keys
    const columns = [
      "Video",
      "Date",
      "Golf Club",
      "Name",
      ...getIndicatorKeys(jsonData).map((key) =>
        key.replace("analysis.indicators.", "")
      ),
    ];

    let csv = columns.join(",") + "\n";

    jsonData.forEach((row) => {
      const data = columns
        .map((col) => {
          if (col === "Video") return row?.videoPath || "";
          if (col === "Date")
            return row?.videoCreated
              ? moment(parseInt(row?.videoCreated)).format("MM/DD/YYYY")
              : "";
          if (col === "Golf Club") return row?.userData?.golfClub || "";
          if (col === "Name") return row?.userData?.fullName || "";
          // Match the cleaned column names with the full indicator keys
          const fullKey = `analysis.indicators.${col}`;
          if (fullKey.startsWith("analysis.indicators.")) {
            const key = col;
            return row?.analysis?.indicators?.[key] || "";
          }
          return "";
        })
        .join(",");
      csv += data + "\n";
    });

    return { csvData: csv, columns };
  }

  function getIndicatorKeys(jsonData) {
    const indicatorKeys = new Set();
    jsonData.forEach((row) => {
      if (row.analysis?.indicators) {
        Object.keys(row.analysis.indicators).forEach((key) => {
          indicatorKeys.add(key); // Use only the key, without prefix
        });
      }
    });
    return Array.from(indicatorKeys);
  }

  const exportSelectedSwings = (swings) => {
    Swal.fire({
      title:
        '<p style="font-size:70%;">Exporting data...</p><br /><img width="10%" src="images/loading.gif" alt="Loading" />',
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      customClass: {
        container: "my-swal",
      },
    });

    downloadJSONAsCSV(swings, "swings");
    Swal.close();
  };

  const exportAllSwings = async () => {
    Swal.fire({
      title:
        '<p style="font-size:70%;">Exporting data...</p><br /><img width="10%" src="images/loading.gif" alt="Loading" />',
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      customClass: {
        container: "my-swal",
      },
    });

    const firebaseUser = firebase.auth().currentUser;
    const uid = firebaseUser.uid;

    const requestBody = {
      limit: 10000,
      offset: 0,
      sort: "videoCreated",
      order: "desc",
      userId: uid,
      loadAll: true,
    };

    if (filterValueUsers.length) {
      requestBody.userId = filterValueUsers[0];
    }

    if (
      filterValueDate &&
      new Date(filterValueDate.startDate).valueOf() !==
        new Date(filterValueDate.endDate).valueOf()
    ) {
      requestBody.dateRangeStart = new Date(
        filterValueDate.startDate
      ).valueOf();
      requestBody.dateRangeEnd = new Date(filterValueDate.endDate).valueOf();
    }

    if (filterValueClub.length) {
      requestBody.club = filterValueClub[0];
    }

    if (filterValueCameraAngle.length) {
      requestBody.cameraAngle = filterValueCameraAngle[0];
    }

    if (filterValueAnalyzed.length) {
      requestBody.analyzed = filterValueAnalyzed[0];
    }

    if (filterValueDominantHand.length) {
      requestBody.domHand = filterValueDominantHand[0];
    }

    const response = await axiosWithToken(
      `${functionBaseUrl}/api/v1/swingList`,
      {
        method: "POST",
        data: requestBody,
      }
    );

    downloadJSONAsCSV(response?.data, "swings");
    Swal.close();
  };

  const classes = useStyles();

  const handleFilterValueChange = (filterName, value) => {
    const toggleMultipleOptions = (prev) => {
      if (prev.includes(value)) {
        return prev.filter((el) => el !== value);
      } else {
        return [...prev, value];
      }
    };

    const toggleSingleOption = (prev) => {
      if (prev[0] === value) {
        return [];
      }
      return [value];
    };

    switch (filterName) {
      case "golfer": {
        setFilterValueUsers(toggleSingleOption);
        break;
      }
      case "date": {
        setFilterValueDate(value.date);
        break;
      }
      case "club": {
        setFilterValueClub(toggleSingleOption);
        break;
      }
      case "cameraAngle": {
        setFilterValueCameraAngle(toggleSingleOption);
        break;
      }
      case "filters": {
        setFilterValueFilters(toggleMultipleOptions);
        break;
      }
      case "analyzed": {
        setFilterValueAnalyzed(toggleSingleOption);
        break;
      }
      case "dominantHand": {
        setFilterValueDominantHand(toggleSingleOption);
        break;
      }
    }
  };

  const handleOrderChange = (e, dir) => {
    switch (e) {
      case 1:
        setSorting({ sort: "userData.golfClub", order: dir ? dir : "desc" });
        break;
      case 2:
        setSorting({ sort: "userData.fullName", order: dir ? dir : "desc" });
        break;
      default:
        setSorting({ sort: "videoCreated", order: dir ? dir : "desc" });
        break;
    }
  };

  const clearAllIndicatorFilters = () => {
    setFilterValueIndicators([]);
    setTableColumns(fixedColumns);
  };

  return (
    <Container component="main" style={{ paddingTop: "20px" }}>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.heading}>
          Swing List
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Identify and quantify golfer's strengths
        </Typography>
      </div>

      <Divider className={classes.divider} />

      <IndicatorSelectList
        isOpen={isIndicatorFilterOpen}
        onClose={() => setIsIndicatorFilterOpen(false)}
        onSave={(data) => setFilterValueIndicators(data)}
        values={filterValueIndicators}
      />

      <div className={classes.container}>
        <Card className={classes.card}>
          <div
            className={classes.container}
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography variant="h4" className={classes.cardText}>
              Swing List
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
              }}
            >
              <Button
                className={classes.topButton}
                disabled={selectedVideos.length === 0}
                onClick={() => downloadVideos(selectedVideos)}
              >
                <GetAppIcon />
              </Button>
              <Button
                className={classes.topButton}
                onClick={() => exportAllSwings()}
              >
                <Typography className={classes.cardText}>Export All</Typography>
              </Button>
              <Button
                className={classes.topButton}
                style={{ background: "#CBB1FB" }}
                disabled={selectedVideos.length === 0}
                onClick={() => exportSelectedSwings(selectedVideos)}
              >
                <Typography className={classes.cardText}>Export</Typography>
              </Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {/* <SelectV3
              values={filterValueUsers}
              name={"golfer"}
              onChange={handleFilterValueChange}
              emptyValue={"Golfer"}
              optionsLabel={"Golfers"}
              allowLocalSearch={true}
              options={filterOptionsUsers}
            /> */}

            <SelectV3WithServerSearch
              values={filterValueUsers}
              name={"golfer"}
              onChange={handleFilterValueChange}
              emptyValue={"Golfer"}
              optionsLabel={"Golfers"}
              options={filterOptionsUsers}
              searchRequest={async (searchValue) => {
                const response = await axiosWithToken(
                  functionBaseUrl + "/api/elastic/searchByNameOrEmail",
                  { method: "POST", data: { text: searchValue } }
                );

                return response.data.map((el) => {
                  return { text: el.userName, value: el.id };
                });
              }}
            />

            <SelectV3WithDatePicker
              values={filterValueDate}
              name={"date"}
              onChange={handleFilterValueChange}
              emptyValue={"Date"}
            />

            <SelectV3WithCategories
              values={filterValueClub}
              name={"club"}
              onChange={handleFilterValueChange}
              emptyValue={"Club"}
              options={[
                {
                  label: "Woods & Hybrids",
                  options: [
                    { value: "D", text: "D" },
                    { value: "3w", text: "3w" },
                    { value: "5w", text: "5w" },
                    { value: "7w", text: "7w" },
                    { value: "9w", text: "9w" },
                    { value: "2h", text: "2h" },
                    { value: "3h", text: "3h" },
                    { value: "4h", text: "4h" },
                    { value: "5h", text: "5h" },
                    { value: "6h", text: "6h" },
                  ],
                },
                {
                  label: "Irons & Wedges",
                  options: [
                    { value: "3i", text: "3i" },
                    { value: "4i", text: "4i" },
                    { value: "5i", text: "5i" },
                    { value: "6i", text: "6i" },
                    { value: "7i", text: "7i" },
                    { value: "8i", text: "8i" },
                    { value: "9i", text: "9i" },
                    { value: "PW", text: "PW" },
                    { value: "GW", text: "GW" },
                    { value: "SW", text: "SW" },
                    { value: "LW", text: "LW" },
                  ],
                },
              ]}
            />

            {/* <SelectV3
              values={filterValueClub}
              name={"club"}
              onChange={handleFilterValueChange}
              emptyValue={"Club"}
              optionsLabel={"Clubs"}
              options={[
                { value: "D", text: "D" },
                { value: "3W", text: "3W" },
                { value: "5W", text: "5W" },
                { value: "7W", text: "7W" },
                { value: "9W", text: "9W" },
                { value: "2H", text: "2H" },
                { value: "3H", text: "3H" },
                { value: "4H", text: "4H" },
                { value: "5H", text: "5H" },
                { value: "6H", text: "6H" },
                { value: "3I", text: "3I" },
                { value: "4I", text: "4I" },
                { value: "5I", text: "5I" },
              ]}
            /> */}

            <SelectV3
              values={filterValueCameraAngle}
              name={"cameraAngle"}
              onChange={handleFilterValueChange}
              emptyValue={"Camera Angle"}
              optionsLabel={"Camera Angles"}
              options={[
                { value: "FON", text: "Face On" },
                { value: "DTL", text: "Down the Line" },
              ]}
            />
            <SelectV3
              values={filterValueFilters}
              name={"filters"}
              onChange={handleFilterValueChange}
              emptyValue={"Filters"}
              optionsLabel={"Filters"}
              showSelectedNumberOnValue
              options={[
                { value: "analyzed", text: "Analyzed" },
                /*{ value: "file", text: "File" },
                { value: "name", text: "Name" },
                { value: "type", text: "Type" },
                { value: "length", text: "Length" },
                { value: "size", text: "Size" },
                { value: "internalLabel", text: "Internal Label" },
                { value: "twoDSslTag", text: "2D SSL Tag" },*/
                { value: "dominantHand", text: "Dominant Hand" },
              ]}
            />

            {filterValueFilters.includes("analyzed") && (
              <SelectV3
                values={filterValueAnalyzed}
                name={"analyzed"}
                onChange={handleFilterValueChange}
                emptyValue={"Analyzed"}
                optionsLabel={"Analyzed"}
                options={[
                  { value: true, text: "True" },
                  { value: false, text: "False" },
                ]}
              />
            )}

            {filterValueFilters.includes("dominantHand") && (
              <SelectV3
                values={filterValueDominantHand}
                name={"dominantHand"}
                onChange={handleFilterValueChange}
                emptyValue={"Dominant Hand"}
                optionsLabel={"Dominant Hand"}
                options={[
                  { value: "Left", text: "Left" },
                  { value: "Right", text: "Right" },
                ]}
              />
            )}

            {filterValueFilters.length ? (
              <div
                style={{ borderLeft: "1px solid #D0D5DD", paddingLeft: "10px" }}
              >
                {filterValueFilters
                  .filter((el) => {
                    return el !== "analyzed" && el !== "dominantHand";
                  })
                  .map((el) => {
                    return (
                      <FilterButton
                        text={el}
                        onClose={() =>
                          setFilterValueFilters((prev) =>
                            prev.filter((fl) => fl !== el)
                          )
                        }
                      />
                    );
                  })}
              </div>
            ) : (
              ""
            )}
          </div>

          <Divider className={classes.divider} />

          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Button
              onClick={() => setIsIndicatorFilterOpen(true)}
              className={classes.addButton}
            >
              <Typography className={classes.cardText}>
                <div className={classes.container}>
                  Add data &nbsp;&nbsp;
                  <img src="/images/icons/plus.png" />
                </div>
              </Typography>
            </Button>
            <Button
              onClick={clearAllIndicatorFilters}
              className={classes.removeButton}
            >
              <Typography
                className={classes.cardText}
                style={{ color: "#B42318" }}
              >
                Remove all &nbsp;&nbsp;
              </Typography>
            </Button>

            {filterValueIndicators.length ? (
              <HorizontalScrollWrapper width="600px">
                {filterValueIndicators.map((el) => {
                  return (
                    <FilterButton
                      text={el.text}
                      onClose={() =>
                        setFilterValueIndicators((prev) =>
                          prev.filter((fl) => fl.value !== el.value)
                        )
                      }
                    />
                  );
                })}
              </HorizontalScrollWrapper>
            ) : (
              <></>
            )}
          </div>

          <Divider
            orientation="vertical"
            flexItem
            className={classes.vertDivider}
          />
        </Card>
      </div>
      <Divider className={classes.divider} />
      {/*<Grid item xs={12}>*/}
      {isLoading ? (
        <img width="4%" src="images/loading.gif" alt="Loading" />
      ) : (
        <Swings
          videos={videos}
          analysisAggregates={analysisAggregates}
          onSelectionChange={(rows) => setSelectedVideos(rows)}
          pageSize={pageSize}
          onPageSizeChange={(size) => setPageSize(size)}
          totalSwings={totalSwings}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          columns={tableColumns}
          onOrderChange={(e, dir) => handleOrderChange(e, dir)}
        />
      )}
      {/*</Grid>*/}
    </Container>
  );
}

export const SwingLibrary = SwingLibraryComponent;
