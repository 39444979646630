import React, { useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";
import { InputV2 } from "../InputV2/InputV2";
import { withStyles } from "@material-ui/styles";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

export const SelectV3 = ({
  values,
  name,
  onChange,
  options,
  emptyValue,
  showSelectedNumberOnValue,
  optionsLabel,
  allowLocalSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [optionsState, setOptionsState] = useState(options);

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  useEffect(() => {
    if (searchValue) {
      function fuzzySearch(input, array) {
        const inputLower = input.toLowerCase();

        return array.filter((item) => {
          let itemLower = `${item.text}`.toLowerCase();
          let searchIndex = 0;

          for (let i = 0; i < itemLower.length; i++) {
            if (itemLower[i] === inputLower[searchIndex]) {
              searchIndex++;
            }
            if (searchIndex === inputLower.length) {
              return true;
            }
          }

          return false;
        });
      }

      setOptionsState(fuzzySearch(searchValue, options));
    } else {
      setOptionsState(options);
    }
  }, [searchValue]);

  useEffect(() => {
    if (isOpen === false) {
      setSearchValue("");
    }
  }, [isOpen]);

  const handleClick = (event) => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 300);
    setAnchorEl(event.currentTarget);
    // setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (value) => {
    onChange(name, value);
    // setIsOpen(false);
    // setAnchorEl(null);
  };

  const handleClose = () => {
    // setIsOpen(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={s.container}>
      <div
        className={`${s.selectWrapper} ${
          values.length ? s.selectActive : s.selectInActive
        } ${isAnimating ? s.animateButton : ""}`}
        onClick={handleClick}
      >
        <div className={s.selectValue}>
          <span>
            {values.length ? (
              showSelectedNumberOnValue ? (
                <>
                  <span
                    style={{
                      backgroundColor: "#E2D2FC",
                      padding: "2px 8px 2px 8px",
                      borderRadius: "14px",
                      marginRight: "5px",
                    }}
                  >
                    {values.length}
                  </span>
                  {emptyValue}
                </>
              ) : (
                options.find((el) => el.value === values[0])?.text
              )
            ) : (
              emptyValue
            )}
          </span>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            minWidth: "200px",
            marginTop: "10px",
            borderRadius: "10px",
          },
        }}
      >
        <div className={s.options}>
          <div className={s.optionsLabel}>{optionsLabel}</div>

          {allowLocalSearch ? (
            <div className={s.searchContainer}>
              <InputV2
                onChange={(name, value) => setSearchValue(value)}
                name="search"
                value={searchValue}
                placeholder="Search"
                disabled={false}
              />
            </div>
          ) : (
            <></>
          )}

          {optionsState.map((el) => (
            <div
              style={{ display: "flex", gap: "5px" }}
              className={s.option}
              key={el.value}
              onClick={() => handleOptionClick(el.value)}
            >
              {values.length && values.includes(el.value) ? (
                <CheckBoxOutlinedIcon style={{ color: "#4009A5" }} />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon
                  style={{ color: "#4009A5" }}
                />
              )}
              <div>{el.text}</div>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};
