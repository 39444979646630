import React, { useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";
import { InputV2 } from "../InputV2/InputV2";
import { withStyles } from "@material-ui/styles";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import { axiosWithToken, functionBaseUrl } from "../../../common/firebase";

export const SelectV3WithServerSearch = ({
  values,
  name,
  onChange,
  options,
  emptyValue,
  showSelectedNumberOnValue,
  optionsLabel,
  searchRequest,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [optionsState, setOptionsState] = useState(options);

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  useEffect(() => {
    let debounceTimer;
    if (searchValue.length) {
      const debounceSearch = async () => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(async () => {
          const resp = await searchRequest(searchValue);

          setOptionsState(resp);
        }, 1000);
      };

      debounceSearch();
    } else {
      setOptionsState(options);
    }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue]);

  const handleClick = (event) => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 300);
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClick = (value) => {
    onChange(name, value);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={s.container}>
      <div
        className={`${s.selectWrapper} ${
          values.length ? s.selectActive : s.selectInActive
        } ${isAnimating ? s.animateButton : ""}`}
        onClick={handleClick}
      >
        <div className={s.selectValue}>
          <span>
            {values.length ? (
              showSelectedNumberOnValue ? (
                <>
                  <span
                    style={{
                      backgroundColor: "#E2D2FC",
                      padding: "2px 8px 2px 8px",
                      borderRadius: "14px",
                      marginRight: "5px",
                    }}
                  >
                    {values.length}
                  </span>
                  {emptyValue}
                </>
              ) : (
                options.find((el) => el.value === values[0])?.text
              )
            ) : (
              emptyValue
            )}
          </span>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            minWidth: "200px",
            marginTop: "10px",
            borderRadius: "10px",
          },
        }}
      >
        <div className={s.options}>
          <div className={s.optionsLabel}>{optionsLabel}</div>

          <div className={s.searchContainer}>
            <InputV2
              onChange={(name, value) => setSearchValue(value)}
              name="search"
              value={searchValue}
              placeholder="Search"
              disabled={false}
            />
          </div>

          {optionsState.map((el) => (
            <div
              style={{ display: "flex", gap: "5px" }}
              className={s.option}
              key={el.value}
              onClick={() => handleOptionClick(el.value)}
            >
              {values.length && values.includes(el.value) ? (
                <CheckBoxOutlinedIcon style={{ color: "#4009A5" }} />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon
                  style={{ color: "#4009A5" }}
                />
              )}
              <div>{el.text}</div>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};
