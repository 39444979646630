import React, { StrictMode } from 'react';
import { Select, MenuItem, Button, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, InputToolbox, Button as ChatscopeButton, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import Swal from 'sweetalert2';

const available_questions = `
<ul style="text-align: left; list-style-type: disc;">
  <li>- What is the ideal value for PLVTRNADR?</li>
  <li>- What is my current value for PLVTRNADR?</li>
  <li>- What is the value of PLVTRNADR?</li>
  <li>- Compare this swing to my swings from past year?</li>
  <li>- Compare this swing to my swings from 2 months ago?</li>
  <li>- Is there a statistically significant correlation between SPNSBNDADR and PLVBNDFIN?</li>
  <li>- What is SWYGAPTOP?</li>
  <li>- What is a slice?</li>
  <li>- What is IMP?</li>
  <li>- What is ADDR?</li>
</ul>
`

function ChatPanel({
    jsonData,
    messages,
    setMessages,
    model,
    models,
    setModel,
    downloadCSV,
    selectSwingIdx,
    setSelectModalOpen,
    getResponse,
    handleExport,
    loadingMessage,
  }) {  
  
  const handleModelChange = (event) => {
    setModel(event.target.value);
  }  

  const handleMessageEdit = (messageIndex, event) => {    
    const editedContent = event.target.innerText;    
    const newMessages = [...messages];
    newMessages[messageIndex].message = editedContent    
    setMessages(newMessages)    
  }

  const handleAttachment = () => {
    setSelectModalOpen(true);    
  }

  const handleSend = (innerHtml, textContent) => {  
    const message = {
      message: textContent,
      sentTime: "just now",
      sender: "user",
    }
    setMessages([...messages, message])
    getResponse(message)
  };

  const showHelpScreen = () => {
    Swal.fire({
      title: 'What questions can you ask?',
      html: available_questions,
      icon: 'question',
      confirmButtonText: 'Ok',
      allowOutsideClick: true,
    });
  }

  return (
    <div style={{ position: "relative", height: "100%" }}>      
      <div>
        <Select value={model} onChange={handleModelChange}>
          {models.map((model, index) => (
            <MenuItem key={model.id} value={model.id}>
              {index==models.length-1 ? "Most Recent Model: ": ""}{model.id}
            </MenuItem>
          ))}
        </Select>
        <Button><FontAwesomeIcon icon={faQuestion} onClick={showHelpScreen}/></Button>
      </div>
      <MainContainer style={{height: '65vh'}}>
        <ChatContainer>
          <MessageList>
            {messages.map((msg, index) => {
              // Check if the message has a 'sender' property
              if (msg.sender) {
                return (
                  <Message key={index} model={msg}>
                    <Message.CustomContent>
                      <div
                        contentEditable={true}
                        suppressContentEditableWarning={true}
                        onBlur={(event) => handleMessageEdit(index, event)}
                      >
                        {msg.message.replace(/\*\*/g, "")}
                      </div>
                      {msg.csv_attachment && (
                        <StrictMode>
                          <ChatscopeButton icon={<FontAwesomeIcon icon={faFileCsv} />} onClick={() => downloadCSV(msg.csv_attachment)}>
                            Download CSV
                          </ChatscopeButton>
                        </StrictMode>
                      )}
                    </Message.CustomContent>
                  </Message>
                );
              }

              // If the message does not have a 'sender' property, return null (renders nothing)
              return null;
              })
            }            
            {
              loadingMessage && <TypingIndicator content="Generating"/>   
            }
          </MessageList>                      
          <InputToolbox>
          {
            selectSwingIdx.length > 0 && (                          
              <ChatscopeButton border onClick={() => {setSelectModalOpen(true)}}>{selectSwingIdx.length} Attachment{selectSwingIdx.length > 1 ? "s": ""}</ChatscopeButton>                          
            )
          }
          </InputToolbox>
          <MessageInput 
            onSend={handleSend}
            onAttachClick={handleAttachment}
            placeholder={"Type message here"}/>                      
        </ChatContainer>
      </MainContainer>
      <Button variant='outlined' onClick={handleExport}>Export Conversations</Button>
    </div>
  );
}

export default ChatPanel;
