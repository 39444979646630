import React, { useEffect, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import s from "./styles.module.css";
import { DateRangePicker } from "react-date-range";
import * as moment from "moment";

export const SelectV3WithDatePicker = ({
  values,
  name,
  onChange,
  emptyValue,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setIsOpen((prev) => !prev);
  };

  const handleChange = (value) => {
    onChange(name, value);
  };

  const handleClose = () => {
    // setIsOpen(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={s.container}>
      <div
        className={`${s.selectWrapper} ${
          values ? s.selectActive : s.selectInActive
        }`}
        onClick={handleClick}
      >
        <div className={s.selectValue}>
          <span>
            {values
              ? `${moment(values.startDate).format("MMM Do")} - ${moment(
                  values.endDate
                ).format("MMM Do")}`
              : emptyValue}
          </span>
          <KeyboardArrowDownIcon />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            minWidth: "200px",
            marginTop: "10px",
            borderRadius: "10px",
          },
        }}
      >
        <DateRangePicker
          ranges={[
            values
              ? values
              : { startDate: Date.now(), endDate: Date.now(), key: "date" },
          ]}
          onChange={handleChange}
        />
      </Popover>
    </div>
  );
};
